import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import FlightModalDetail from './FlightModalDetail';
import { agentbookinghistoryDispatch } from '../../reducers/UserReducer';
import { useHistory } from 'react-router-dom';
import {
	FlightMailTicketDispatch,
	FlightReleasePNRDispatch,
} from '../../reducers/HomeReducer';
import {
	setBookingHistoryID,
	toggleFlightDetailModal,
	setSelectedBookingHistory,
	toggleCancelModal,
	setEditPrice,
} from '../../reducers/UiReducer';
import CancelTicketModal from './CancelTicketModal';
const tableHeaders = [
	'ORDER_ID',
	'DEPART FROM',
	'JOURNEY TO',
	'DATE OF BOOKING',
	'JOURNEY DATE',
	'TICKET COST',
	'STATUS',
	'PNR',
	'ACTION',
];

const BookingQueue = () => {
	const dispatch = useDispatch();
	const [val, setVal] = useState('');
	const [start, setSDate] = useState(new Date());
	const [end, setEDate] = useState(new Date());
	const { bookinghistory, holdhistoryagent, agentlogin } = useSelector(
		(state) => state.user
	);
	const history = useHistory();
	useEffect(() => {
		dispatch(
			agentbookinghistoryDispatch(agentlogin && agentlogin.result.result.id)
		);
		dispatch(setEditPrice(null));
	}, []);
	const [data, setData] = useState('');
	console.log('Booking', holdhistoryagent);
	const handleMail = (item) => {
		let formMail = {
			orderId: item.orderId,
			userEmail: item.userEmail,
		};
		// dispatch(FlightMailTicketDispatch(formMail));
		dispatch(FlightMailTicketDispatch(formMail));
		window.alert('Ticket Send Successfully');
	};
	const handleDate = (event) => {
		// console.log("first",event.target.value)
		setSDate(new Date(event.target.value));
	};
	const handleDate2 = (event) => {
		// console.log("first2",event.target.value)
		setEDate(new Date(event.target.value));
	};
	const handleFilter = () => {
		let results = [];
		results =
			holdhistoryagent &&
			holdhistoryagent.result &&
			holdhistoryagent.result.flights.filter((item) => {
				var datef = new Date(item.dob);
				return datef >= start && datef <= end;
			});
		setData(results);
		console.log('results', results);
	};
	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let results = [];
		console.log(value);
		// setVal(value);
		if (event.target.value.toLowerCase() === 'new') {
			results =
				holdhistoryagent &&
				holdhistoryagent.result &&
				holdhistoryagent.result.flights.slice().sort((a, b) => {
					return b.id - a.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'old') {
			results =
				holdhistoryagent &&
				holdhistoryagent.result &&
				holdhistoryagent.result.flights.slice().sort((a, b) => {
					return a.id - b.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'lh') {
			results =
				holdhistoryagent &&
				holdhistoryagent.result &&
				holdhistoryagent.result.flights.slice().sort((a, b) => {
					return a.amount - b.amount;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'hl') {
			results =
				holdhistoryagent &&
				holdhistoryagent.result &&
				holdhistoryagent.result.flights.slice().sort((a, b) => {
					return b.ticketCost - a.ticketCost;
				});
			setData(results);
		} else {
			results =
				holdhistoryagent &&
				holdhistoryagent.result &&
				holdhistoryagent.result.flights.filter((item) => {
					return item.type.toLowerCase().includes(value.toLowerCase());
				});
			setData(results);
		}
		// results =
		//   holdhistoryagent &&
		//   holdhistoryagent.result &&
		//   holdhistoryagent.result.flights.filter((item) => {
		//     return (
		//       item.orderId.toLowerCase().includes(value.toLowerCase()) ||
		//       item.userEmail.toLowerCase().includes(value.toLowerCase()) ||
		//       item.origin.toLowerCase().includes(value.toLowerCase()) ||
		//       item.destination.toLowerCase().includes(value.toLowerCase()) ||
		//       item.flightType.toLowerCase().includes(value.toLowerCase()) ||
		//       item.journeyType.toLowerCase().includes(value.toLowerCase()) ||
		//       item.journeyType.toLowerCase().includes(value.toLowerCase()) ||
		//       item.supplier.toLowerCase().includes(value.toLowerCase()) ||
		//       item.bookingStatus.toLowerCase().includes(value.toLowerCase()) ||
		//       item.travelDate.toLowerCase().includes(value.toLowerCase())
		//     );
		//   });
		// setData(results);
		// console.log(results);
	};
	console.log('Result', data);
	// const handleSearch =

	return (
		<>
			{holdhistoryagent &&
				holdhistoryagent?.result?.flights?.length > 0 &&
				holdhistoryagent?.result?.flights.map((i) => {
					return (
						<Box
							style={{
								border: '1px solid lightgrey',
								marginLeft: '50px',
								marginRight: '50px',
								borderRadius: 5,
								marginTop: 20,
							}}>
							<Grid container alignItems='center' style={{ padding: '10px' }}>
								<Grid item xs={4}>
									{JSON.parse(i?.selectedPax)?.map((item, index) => {
										return (
											<h6>{`${index + 1}. ${item.title} ${item.firstname} ${
												item.lastname
											}`}</h6>
										);
									})}
									{/* {JSON.parse(i?.selectedPax)} */}
									{console.log('SelectedPAx', JSON.parse(i?.selectedPax))}
								</Grid>
								<Grid item xs={4}>
									<p>
										{i.carrier} | 971 | {i.travelDate}
									</p>
									<p>
										{i.origin}-{i.destination}
									</p>
								</Grid>
								<Grid item xs={4}>
									<Box
										style={{
											padding: 20,
											background: 'rgba(243,243,243,.3)',
											border: '1px solid lightgrey',
											borderRadius: '20px',
										}}>
										<Grid container>
											<Grid item xs={6}>
												<h6>PNR:</h6>
												<h6>Ref No:</h6>
												<h6>Status: </h6>
											</Grid>
											<Grid item xs={6}>
												<h6>
													{i.carrier} - {i.bookingRefrence}
												</h6>
												<h6>{i.bookingId} </h6>
												<h6>Hold</h6>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
							<Divider style={{ border: '1px solid grey' }} />
							<Box style={{ background: '#EAF0F8', padding: 5 }}>
								<Grid container>
									<Grid item xs={8}></Grid>
									<Grid item xs={4}>
										<Box display={'flex'} justifyContent='space-around'>
											<Box
												style={{
													background: '#246197',
													padding: 5,
													borderRadius: 5,
												}}>
												<span
													style={{ color: 'white', cursor: 'pointer' }}
													// onClick={() => dispatch(FlightReleasePNRDispatch())}
												>
													Release
												</span>
											</Box>
											<Box
												style={{
													background: '#246197',
													padding: 5,
													borderRadius: 5,
												}}>
												<span style={{ color: 'white', cursor: 'pointer' }}>
													Open
												</span>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Box>
					);
				})}
			<FlightModalDetail />
			<CancelTicketModal />
		</>
	);
};

export default BookingQueue;
