import React, { useEffect } from 'react';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import PaymentLoader from '../../components/reusableComponents/PaymentLoader';
import Footer from './Footer';
const PaymentVerify = () => {
	return (
		<>
			<HeaderSection />
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: '#F8F8F8AD',
				}}>
				<div
					style={{
						left: '20%',
						top: '30%',
						zIndex: 1000,
						position: 'absolute',
					}}>
					<>
						<div style={{ fontSize: 35, textAlign: 'center' }}>
							!!Ticket might have been issued.!!
						</div>
						<div style={{ fontSize: 35, textAlign: 'center' }}>
							Please Contact Website Administrator
						</div>
					</>
				</div>
			</div>
			{/* <Footer /> */}
			{/* <PaymentLoader /> */}
		</>
	);
};

export default PaymentVerify;
