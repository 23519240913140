import React from 'react';
import { Container, Box, Grid, Divider } from '@material-ui/core';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import Footer from './Footer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const data = [
	{
		fontawsClass: 'fa fa-building-o',
		title: 'Our Offices',
		desc: 'Located across the country, ready to assist in planning & booking your perfect vacation.',
		footer: 'Locate Us',
	},
	{
		fontawsClass: 'fa fa-phone',
		title: 'Call us',
		desc: "Request a quote, or just chat about your next vacation. We're always happy to help!",
		footer: '(+91) 8877773388',
	},
	{
		fontawsClass: 'fa fa-envelope',
		title: 'Write to us',
		desc: 'Be it an enquiry, feedback or a simple suggestion, write to us.',
		footer: 'info@musafirbazar.com',
	},
	{
		fontawsClass: 'fa fa-share-alt',
		title: 'Connect with us',
		desc: 'Check out reviews, podcasts, blogs and more...',
		footer: '',
	},
];
const Holidayslist = () => {
	const history = useHistory();
	return (
		<>
			<Box>
				{/* <Headers /> */}
				<HeaderSection />
				<Divider style={{ marginTop: 100 }} />
				<Container style={{ marginTop: 30 }}>
					<Box
						style={{
							boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
							background: 'white',
							padding: 15,
							borderRadius: 10,
						}}>
						<Grid container>
							<Grid item xs={12} lg={6}>
								<h4>Himachal Pradesh Tour Packages</h4>
								<p style={{ fontWeight: 'bold' }}>
									The Himalayan land of pristine nature, encompasses the noble
									souls, just like its soil and snow, being the heaven’s true
									glow.
								</p>
								<p style={{ color: 'grey' }}>
									The region full of picturesque, popular, and yet offbeat
									places lie in this mystical land, full of flawless natural
									treasures. Bollywood’s personal favourite and a lively winter
									sports destination, it is the dearest choice of every travel
									enthusiast. The world of new experiences awaits you!
									Experience adventure with thrilling sports like horse riding,
									paragliding, mountain biking, zorbing at Solang Valley, and
									equally enchanting sightseeing experiences at Shimla and
									Rohtang Pass. Veena World understands the best of your
									interests and preferences.
								</p>
								<p style={{ color: 'grey' }}>
									The land of divinity with the two most ancient temples in
									India, namely the Hidimba Devi Temple and Vashisht Kund Temple
									along with the residence of His Holiness Dalai Lama,
									Dharamshala are the star highlights of our Himachal tour
									packages. The northern part of India, a pure delight for the
									mountain and nature lovers, has inspired some of our
									well-assorted Himachal Pradesh tour packages. The ambience of
									Himachal Pradesh is welcoming just like the warm-hearted
									people of this state. The thrill of winter adventure lures
									every explorer’s adrenaline urge. The poetic feel of the
									destination is so romantic that we carefully hand-pick special
									Himachal tour package for couples. We use our passionate
									unrivalled knowledge to design your perfect Himalayan
									vacation!
								</p>
							</Grid>

							<Grid
								item
								xs={12}
								lg={6}
								style={{
									backgroundImage: `url(${'https://img.veenaworld.com/og-images/himachal-pradesh-og-1.jpg'})`,
									backgroundPosition: 'center!important',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									borderRadius: '0 9px 9px 0',
									position: 'relative',
								}}>
								<div
									style={{
										position: 'absolute',
										zIndex: 1,
										background:
											'linear-gradient(to right,white,rgba(255,255,255,0))',
										width: '100%',
										height: '100%',
										borderRadius: '0 9px 9px 0',
									}}></div>
							</Grid>
						</Grid>
					</Box>
				</Container>
				<Container style={{ marginTop: 50 }}>
					<Grid container style={{ alignItems: 'center' }}>
						<Grid item xs={12} lg={6}>
							<span>29 Himachal Pradesh Holiday Packages</span>
							<br />
							<p style={{ fontSize: 12 }}>
								Showing 1-10 packages from 29 packages
							</p>
						</Grid>
						<Grid item xs={12} lg={6}>
							<h5>29 departures available</h5>
						</Grid>
					</Grid>
				</Container>
				{/* <Container> */}
				{/* <Grid container spacing={4}>
				<Grid
					item
					xs={4}
					style={
						{
							// boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
							// background: 'white',
						}
					}>
					<span>29 Himachal Pradesh Holiday Packages</span>
					<br />
					<p style={{ fontSize: 12 }}>Showing 1-10 packages from 29 packages</p>
				</Grid>
				<Grid
					item
					xs={8}
					style={
						{
							// boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
							// background: 'white',
						}
					}>
					<h5>29 departures available</h5>
				</Grid>
			</Grid> */}
				{/* </Container> */}
				<Container>
					<Grid container spacing={4}>
						<Grid item xs={12} lg={4}>
							<Box
								style={{
									boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
									background: 'white',
									padding: 15,
									borderRadius: 10,
								}}>
								<Box
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
										display: 'flex',
									}}>
									<span style={{ fontSize: 14 }}>Find your tour</span>
									<span style={{ fontSize: 12 }}>Reset All</span>
								</Box>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Box
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
										display: 'flex',
									}}>
									<span style={{ fontSize: 14 }}>Package Type</span>
									{/* <span style={{ fontSize: 12 }}>Reset All</span> */}
								</Box>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Box
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
										display: 'flex',
									}}>
									<span style={{ fontSize: 14 }}>Package Type</span>
									{/* <span style={{ fontSize: 12 }}>Reset All</span> */}
								</Box>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Box
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
										display: 'flex',
									}}>
									<span style={{ fontSize: 14 }}>Package Type</span>
									{/* <span style={{ fontSize: 12 }}>Reset All</span> */}
								</Box>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Box
									style={{
										justifyContent: 'space-between',
										alignItems: 'center',
										display: 'flex',
									}}>
									<span style={{ fontSize: 14 }}>Package Type</span>
									{/* <span style={{ fontSize: 12 }}>Reset All</span> */}
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={8}>
							<Box
								style={{
									boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
									background: 'white',
									padding: 15,
									borderRadius: 10,
								}}>
								{Array.from(Array(10)).map(() => {
									return (
										<>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={3}>
													<img
														src='https://img.veenaworld.com/customized-holidays/india/himachal/shhp11/shhp11-thb.jpg'
														width='100%'
														style={{ height: 200, borderRadius: 5 }}
													/>
												</Grid>
												<Grid item xs={12} lg={6}>
													<h5 style={{ fontWeight: 'bold' }}>
														Dalhousie Dharamshala
													</h5>
													<p>5 Days 4 Cities</p>
													<Box
														style={{
															display: 'flex',
															alignItems: 'center',
															// justifyContent: 'space-around',
														}}>
														<Box
															style={{ display: 'flex', alignItems: 'center' }}>
															<i class='fa-solid fa-hotel'></i>
															<span style={{ marginLeft: 5 }}>Hotel</span>
														</Box>
														<Box
															style={{
																display: 'flex',
																alignItems: 'center',
																marginLeft: 10,
															}}>
															<i class='fa-solid fa-hotel'></i>
															<span style={{ marginLeft: 5 }}>Hotel</span>
														</Box>
														<Box
															style={{
																display: 'flex',
																alignItems: 'center',
																marginLeft: 10,
															}}>
															<i class='fa-solid fa-hotel'></i>
															<span style={{ marginLeft: 5 }}>Hotel</span>
														</Box>
													</Box>

													<p
														style={{
															fontSize: 12,
															color: 'grey',
															marginTop: 10,
														}}>
														Call now 1800 22 7979 to customize this holiday
													</p>
												</Grid>
												<Grid item xs={12} lg={3} style={{ textAlign: 'end' }}>
													<span>STARTS FROM</span>
													<h5 style={{ fontWeight: 'bold' }}>₹27,500</h5>
													<p style={{ fontSize: 12, color: 'grey' }}>
														per person on twin sharing
													</p>
													<Box
														onClick={() => history.push('/holidaysdetal')}
														style={{
															textAlign: 'center',
															background: 'yellow',
															padding: '8px',
															borderRadius: 5,
															border: '1px solid yellow',
															cursor: 'pointer',
														}}>
														<span>View Details</span>
													</Box>
													<Box
														style={{
															textAlign: 'center',
															// background: 'yellow',
															padding: '8px',
															borderRadius: 5,
															border: '1px solid grey',
															marginTop: 10,
														}}>
														<span>Enquire Now</span>
													</Box>
												</Grid>
											</Grid>
											<Divider style={{ marginTop: 20, marginBottom: 20 }} />
										</>
									);
								})}
							</Box>
						</Grid>
					</Grid>
				</Container>
				{/* <Container style={{ marginTop: 20, marginBottom: 20 }}>
					<Grid container spacing={4}>
						{data.map((r, index) => {
							return (
								<Grid item xs={12} lg={3}>
									<Box>
										<i class={r.fontawsClass} aria-hidden='true'></i>
										<span style={{ marginLeft: 10, fontSize: 20 }}>
											{r.title}
										</span>
										<br />
										<p style={{ fontSize: 12 }}>{r.desc}</p>
										{index === 3 ? (
											<>
												<i
													class='fa fa-facebook-square'
													aria-hidden='true'
													style={{ fontSize: 30 }}></i>
												<i
													class='fa fa-youtube-play'
													aria-hidden='true'
													style={{ fontSize: 30, marginLeft: 10 }}></i>
												<i
													class='fa fa-linkedin'
													aria-hidden='true'
													style={{ fontSize: 30, marginLeft: 10 }}></i>
												<i
													class='fa fa-instagram'
													aria-hidden='true'
													style={{ fontSize: 30, marginLeft: 10 }}></i>
												<i
													class='fa fa-twitter'
													aria-hidden='true'
													style={{ fontSize: 30, marginLeft: 10 }}></i>
											</>
										) : (
											<span style={{ color: 'darblue', fontWeight: 'bold' }}>
												{r.footer}
											</span>
										)}
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Container> */}
				<Footer />
				{/* <Footer /> */}
			</Box>
		</>
	);
};

export default Holidayslist;
