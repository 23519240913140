import axios from 'axios';
import { axiosInstance } from '../Axios';
import { DebitAdminBusAmount } from './HomeReducer';
import {
	loader,
	storeData,
	toggleEasebuzzModal,
	togglePGModal,
	toggleSessionExpiredModal,
} from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const BusReducer = createSlice({
	name: 'Bus',
	initialState: {
		success: false,
		error: null,
		loadingbus: false,
		loadingbus2: false,
		layoutloading: false,
		buscity: sessionStorage.getItem('buscity')
			? JSON.parse(sessionStorage.getItem('buscity'))
			: null,
		busSearch: null,
		busDes: null,
		seatLayout: null,
		busBlock: sessionStorage.getItem('busBlock')
			? JSON.parse(sessionStorage.getItem('busBlock'))
			: null,
		busBooking: null,
		busBookingDetails: null,
		addBusSearch: null,
		addBusBooking: null,
		debitbuspayment: null,
		busMarkup: null,
	},
	reducers: {
		cityBusRequest(state, action) {
			state.loadingbus = true;
		},
		cityBusSuccess(state, action) {
			state.loadingbus = false;
			state.buscity = action.payload;
			storeData('buscity', action.payload);
			state.error = null;
		},
		cityBusFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		addBusSearchRequest(state, action) {
			state.loadingbus = true;
		},
		addBusSearchSuccess(state, action) {
			state.loadingbus = false;
			state.addBusSearch = action.payload;
			state.error = null;
		},
		addBusSearchFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		debitBusPaymentRequest(state, action) {
			state.loadingbus = true;
		},
		debitBusPaymentSuccess(state, action) {
			state.loadingbus = false;
			state.debitBusPayment = action.payload;
			state.error = null;
		},
		debitBusPaymentFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		addBusBookingRequest(state, action) {
			state.loadingbus2 = true;
		},
		addBusBookingSuccess(state, action) {
			state.loadingbus2 = false;
			state.addBusBooking = action.payload;
			state.error = null;
		},
		addBusBookingFail(state, action) {
			state.loadingbus2 = false;
			state.error = action.payload;
		},
		busSearchRequest(state, action) {
			state.loadingbus = true;
		},
		busSearchSuccess(state, action) {
			state.loadingbus = false;
			state.busSearch = action.payload;
			state.error = null;
		},
		busSearchFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busDesRequest(state, action) {
			state.loadingbus2 = true;
		},
		busDesSuccess(state, action) {
			state.loadingbus2 = false;
			state.busDes = action.payload;
			state.error = null;
		},
		busDesFail(state, action) {
			state.loadingbus2 = false;
			state.error = action.payload;
		},
		seatLayoutRequest(state, action) {
			state.layoutloading = true;
		},
		seatLayoutSuccess(state, action) {
			state.layoutloading = false;
			state.seatLayout = action.payload;
			state.error = null;
		},
		seatLayoutFail(state, action) {
			state.layoutloading = false;
			state.error = action.payload;
		},
		busBlockRequest(state, action) {
			state.loadingbus = true;
		},
		busBlockSuccess(state, action) {
			state.loadingbus = false;
			state.busBlock = action.payload;
			storeData('busBlock', action.payload);
			state.error = null;
		},
		busBlockFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busBookingRequest(state, action) {
			state.loadingbus = true;
		},
		busBookingSuccess(state, action) {
			state.loadingbus = false;
			state.busBooking = action.payload;
			state.error = null;
		},
		busBookingFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busBookingDetailsRequest(state, action) {
			state.loadingbus = true;
		},
		busBookingDetailsSuccess(state, action) {
			state.loadingbus = false;
			state.busBookingDetails = action.payload;
			state.error = null;
		},
		busBookingDetailsFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		debitbuspaymentRequest(state, action) {
			state.loadingbus = true;
		},
		debitbuspaymentSuccess(state, action) {
			state.loadingbus = false;
			state.debitbuspayment = action.payload;
			state.error = null;
		},
		debitbuspaymentFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busMarkupRequest(state, action) {
			state.loadingbus = true;
		},
		busMarkupSuccess(state, action) {
			state.loadingbus = false;
			state.busMarkup = action.payload;
			state.error = null;
		},
		busMarkupFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
	},
});
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': process.env.REACT_APP_APIKEY,
	},
};
const { actions } = BusReducer;

export const {
	cityBusRequest,
	cityBusSuccess,
	cityBusFail,
	addBusSearchRequest,
	addBusSearchSuccess,
	addBusSearchFail,
	addBusBookingRequest,
	addBusBookingSuccess,
	addBusBookingFail,
	debitBusPaymentRequest,
	debitBusPaymentSuccess,
	debitBusPaymentFail,
	busSearchRequest,
	busSearchSuccess,
	busSearchFail,
	busDesRequest,
	busDesSuccess,
	busDesFail,
	seatLayoutRequest,
	seatLayoutSuccess,
	seatLayoutFail,
	busBlockRequest,
	busBlockSuccess,
	busBlockFail,
	busBookingRequest,
	busBookingSuccess,
	busBookingFail,
	busBookingDetailsRequest,
	busBookingDetailsSuccess,
	busBookingDetailsFail,
	debitbuspaymentRequest,
	debitbuspaymentSuccess,
	debitbuspaymentFail,
	busMarkupRequest,
	busMarkupSuccess,
	busMarkupFail,
} = actions;

export const fetchBusCityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityBusRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busallsources',
			bodyData,
			config
		);

		console.log(data);
		dispatch(cityBusSuccess(data));
	} catch (error) {
		dispatch(
			cityBusFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBusSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addBusSearchRequest());
		const { data } = await axiosInstance.post(
			'Bus/addsearchbus',
			bodyData,
			config
		);

		console.log(data);
		dispatch(addBusSearchSuccess(data));
		if (data.status === 200) {
			dispatch(fetchBusSearchDispatch(bodyData));
		}
	} catch (error) {
		dispatch(
			addBusSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBusBookingDispatch =
	(bodyData, formData, pay, location, history) => async (dispatch) => {
		try {
			dispatch(addBusBookingRequest());
			const { data } = await axiosInstance.post(
				'bus/addbookingbus',
				bodyData,
				config
			);

			console.log(data);
			dispatch(addBusBookingSuccess(data));
			if (data.status === 200) {
				dispatch(busBlockDispatch(formData, location, history, pay, bodyData));
			} else {
				alert('Technical Error has Been Found !!');
			}
		} catch (error) {
			alert('Technical Error has Been Found !!');
			dispatch(
				addBusBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const debitBusPaymentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(debitBusPaymentRequest());
		const { data } = await axiosInstance.post(
			'bus/debitbuspayment',
			bodyData,
			config
		);

		console.log(data);
		dispatch(debitBusPaymentSuccess(data));
		// if (data.status === 200) {
		// 	dispatch(fetchBusSearchDispatch(bodyData));
		// }
	} catch (error) {
		dispatch(
			debitBusPaymentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchBusCityDesDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(busDesRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busdestinationforsource',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busDesSuccess(data));
	} catch (error) {
		dispatch(
			busDesFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const fetchBusSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(busSearchRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/bussearch',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busSearchSuccess(data));
	} catch (error) {
		dispatch(
			busSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchSeatLayoutDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(seatLayoutRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busseatlayout',
			bodyData,
			config
		);

		console.log(data);
		dispatch(seatLayoutSuccess(data));
	} catch (error) {
		dispatch(
			seatLayoutFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const busBlockDispatch =
	(bodyData, location, history, pay, bodyData2) => async (dispatch) => {
		try {
			dispatch(busBlockRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busblock',
				bodyData,
				config
			);

			console.log('data', pay);
			dispatch(busBlockSuccess(data));
			if (data.status === 200) {
				if (bodyData2.paymentMode === 'OFFLINE') {
					// console.log('first');
					dispatch(togglePGModal(true));
				} else {
					let form3 = {
						orderId: location.state.orderId,
						paymentId: bodyData.paymentId,
					};

					// dispatch(toggleEasebuzzModal(true));
					var options = {
						// key: 'rzp_test_1xufv8E9EKecYL',
						// key_secret: 'Ui7h6GeBLYxy1y75QaV6TgBz',
						key: 'rzp_live_E3pAr0GJChRFhN',
						key_secret: 'yYUKywMunQRat5KH7sONz3I6',
						amount:
							Number(
								Number(
									location.state.selectSeat &&
										location.state?.selectSeat
											?.map((item) => parseInt(item.totalFare))
											.reduce((prev, next) => prev + next)
								) +
									Number(
										Math.round(
											Number(
												Number(
													location.state.selectSeat &&
														location.state?.selectSeat
															?.map((item) => parseInt(item.totalFare))
															.reduce((prev, next) => prev + next)
												) * 2.5
											) / 100
										)
									)
							) * 100,
						currency: 'INR',
						name: 'Bus Booking',
						description: '',
						handler: (res) => {
							if (res) {
								dispatch(
									debitbuspaymentDispatch(
										form3,
										data.result.blockKey,
										history,
										location
									)
								);
							}
						},
						prefill: {
							name: 'FlyHala',
							email: location.state.data.email,
							contact: location.state.data.phone,
						},
						notes: {
							address: '',
						},
						theme: {
							color: '#3399cc',
						},
					};
					var pay = new window.Razorpay(options);
					pay.open();
				}
			} else {
				// dispatch(toggleSessionExpiredModal(true));
				alert('Seat is no longer available');
			}
		} catch (error) {
			dispatch(
				busBlockFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const busBookingDispatch =
	(bodyData, history, location, payment) => async (dispatch) => {
		try {
			dispatch(busBookingRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busbooking',
				bodyData,
				config
			);

			console.log(data);
			dispatch(busBookingSuccess(data));
			if (data.status === 200) {
				let form = {
					ticketId: data.result,
					orderId: payment.orderId,
					paymentId: payment.paymentId,
				};
				let markup = {
					orderId: payment.orderId,
					payment: payment.paymentId,
				};
				dispatch(busBookingDetailsDispatch(form, history, location));
				dispatch(busMarkupAmountDispatch(markup));
			}
		} catch (error) {
			dispatch(
				busBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const busBookingDetailsDispatch =
	(bodyData, history, location) => async (dispatch) => {
		try {
			dispatch(busBookingDetailsRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busbookingdetails',
				bodyData,
				config
			);

			console.log(data);
			dispatch(busBookingDetailsSuccess(data));
			if (data.status === 200) {
				history.push({
					pathname: '/busticket',
					state: {
						bookingDetail: data,
						detail: location,
					},
				});
			}
		} catch (error) {
			dispatch(
				busBookingDetailsFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const debitbuspaymentDispatch =
	(bodyData, blockKey, history, location) => async (dispatch) => {
		try {
			dispatch(debitbuspaymentRequest());
			const { data } = await axiosInstance.post(
				'bus/debitbuspayment',
				bodyData,
				config
			);

			console.log(data);
			dispatch(debitbuspaymentSuccess(data));
			if (data.status === 200) {
				let formdata = {
					blockKey: blockKey,
				};
				dispatch(busBookingDispatch(formdata, history, location, bodyData));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(
				debitbuspaymentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const busMarkupAmountDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(busMarkupRequest());
		const { data } = await axiosInstance.post(
			'bus/markuptopartner',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busMarkupSuccess(data));
	} catch (error) {
		dispatch(
			busMarkupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export default BusReducer;
