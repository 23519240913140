import React, { useEffect, useState } from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	useTheme,
	useMediaQuery,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import bus from '../assets/images/bus.png';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { setSelectedBus, setSelectedDesBus } from '../reducers/UiReducer';
import DesktopDateBus from '../components/reusableComponents/DesktopDateBus';
import { fetchBusCityDesDispatch } from '../reducers/BusReducer';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'grey',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '.8em',
		},
	},
	radio2: {
		'& .MuiRadio-root': {
			color: 'black',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '.8em',
		},
	},
	popper: {
		'& .MuiAutocomplete-listbox': {
			'& :hover': {
				color: 'rgb(239, 51, 57)',
			},
		},
	},
}));
const SearchBus2 = () => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const classes = useStyles();
	const { city } = useSelector((state) => state.home);
	const { buscity, busDes } = useSelector((state) => state.bus);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [filterData, setFilterData] = useState('');
	const [filterData2, setFilterData2] = useState('');
	const [trip, setTrip] = useState('oneway');
	const [going, setGoing] = useState('CCU');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { origin, depart, selectedBus, selectedDesBus } = useSelector(
		(state) => state.ui
	);

	const handleSubmit = (data) => {
		let formData = {
			departure_city: selectedBus.name.split(' ')[0],
			arrival_city: selectedDesBus.name.split(' ')[0],
			sourceid: selectedBus.id,
			destinationid: selectedDesBus.id,
			dateofjourney: origin,
		};
		history.push({
			pathname: '/buslist',
			state: { formdata: formData, origin: origin },
		});
	};

	const handleSearchOrigin = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		// console.log(value);
		results =
			buscity &&
			buscity?.result &&
			buscity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedBus(results ? results[0] : null));
			if (results) {
				let formData = {
					source: results[0].id,
				};
				dispatch(fetchBusCityDesDispatch(formData));
			}
		}
		if (results?.length <= 80) {
			setFilterData(results);
		}
	};
	const handleSearchOrigin2 = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		results =
			busDes &&
			busDes?.result &&
			busDes?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);

		if (results?.length < 80) {
			setFilterData2(results);
		}
	};
	console.log('first', filterData);
	useEffect(() => {
		handleSearchOrigin();
	}, []);
	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form style={{}}>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} md={12} lg={3}>
									<div className={classes.root} style={{ width: '100%' }}>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
											}}>
											From
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											value={selectedBus}
											options={
												filterData && filterData.length > 0 ? filterData : []
											}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.name} (${option.state})`
											}
											renderOption={(props, option) => (
												<Box
													mr={'5px'}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<img loading='lazy' width='20' src={bus} />
													<p style={{ marginLeft: 5, fontSize: 12 }}>
														{option.name} ({option.state})
													</p>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedBus(value));
												console.log('Des', value);
												let formData = {
													source: value.id,
												};
												dispatch(fetchBusCityDesDispatch(formData));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													onChange={handleSearchOrigin}
													name='origin'
												/>
											)}
										/>
									</div>
									{/* <span style={{ color: "#4a4a4a" }}>
                      {selectedBus ? `${selectedBus?.state}` : ""}
                    </span> */}
									{/* </Box> */}
								</Grid>
								<Grid item xs={12} md={12} lg={3}>
									{/* <Box
                    style={{
                      border: "1px solid #767b8e",
                      borderRadius: 5,
                      paddingLeft: 13,
                    }}
                  > */}
									<div className={classes.root}>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
											}}>
											To
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											value={
												selectedDesBus
													? selectedDesBus
													: busDes?.result?.cities[0]
											}
											options={
												filterData2 && filterData2.length > 0 ? filterData2 : []
											}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.name} (${option.state})`
											}
											renderOption={(props, option) => (
												<Box
													mr={'5px'}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<img loading='lazy' width='20' src={bus} />
													<p style={{ marginLeft: 5, fontSize: 12 }}>
														{option.name} ({option.state})
													</p>
												</Box>
											)}
											onChange={(event, value) => {
												// setFieldValue('Destination', value.code);
												// setGoing(value.code);
												console.log('Des', value);
												dispatch(setSelectedDesBus(value));
												// dispatch(fetchBusCityDesDispatch())
											}}
											renderInput={(params) => (
												<TextField {...params} onChange={handleSearchOrigin2} />
											)}
										/>
									</div>
									{/* {selectedDesBus ? `${selectedDesBus?.state}` : ""} */}
									{/* </Box> */}
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={trip == 'oneway' ? 3 : trip == 'roundtrip' ? 6 : 3}>
									{/* <div className={classes.root}> */}
									<span
										style={{ color: 'black', fontSize: matches ? 12 : '.8em' }}>
										Depart Date
									</span>
									{/* <Box
                    style={{
                      border: "1px solid #767b8e",
                      borderRadius: 5,
                      paddingLeft: 13,
                    }}
                  > */}
									{/* <span style={{ fontSize: 20 }}>{`  ${new Date(
                      origin.substr(0, 10)
                    ).getDate()} ${new Date(
                      origin.substr(0, 10)
                    ).toLocaleString("en-us", {
                      month: "short",
                    })},2022`}</span> */}
									<DesktopDateBus />
									{/* </Box> */}
									{/* {trip == 'oneway' ? (
										<DesktopDateBus />
									) : trip == 'roundtrip' ? (
										<DateRangeNew />
									) : (
										<DesktopDateBus />
									)} */}
									{/* <DateRangeNew /> */}
									{/* <DesktopDateNew /> */}
									{/* </div> */}
								</Grid>
								{/* <Grid item lg={3}></Grid> */}
								{/* <Grid item xs={12} md={6} lg={6}>
									<ul class='menu' style={{ marginTop: 20, borderRadius: 2 }}>
										<li>
											<a href='#' style={{ padding: 10 }}>
												Travellers,Classes
											</a>

											<ul style={{ width: '100%' }}>
												<li>
													<Box
														style={{ padding: 10 }}
														display='flex'
														justifyContent='space-between'
														textAlign='center'
														alignItems='center'>
														<BodyText four textStyle={{ color: 'white' }}>
															Adult
														</BodyText>
														<Box display='flex' alignItems='center'>
															<Box
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	adult === 0 ? '' : setAdult(adult - 1)
																}>
																<BodyText
																	textStyle={{
																		fontSize: 24,
																		color: 'white',
																	}}>
																	-
																</BodyText>
															</Box>
															<Box
																ml={'15px'}
																style={{
																	cursor: 'pointer',
																}}>
																<BodyText
																	textStyle={{ fontSize: 24, color: 'white' }}>
																	{adult}
																</BodyText>
															</Box>
															<Box
																ml={'15px'}
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	adult === 9 ? '' : setAdult(adult + 1)
																}>
																<BodyText
																	textStyle={{
																		fontSize: 24,
																		color: 'white',
s																	}}>
																	+
																</BodyText>
															</Box>
														</Box>
													</Box>
												</li>
												<li>
													<Box
														style={{ padding: 10 }}
														display='flex'
														justifyContent='space-between'
														textAlign='center'
														alignItems='center'>
														<BodyText four textStyle={{ color: 'white' }}>
															Child
														</BodyText>
														<Box display='flex' alignItems='center'>
															<Box
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	adult === 0 ? '' : setAdult(adult - 1)
																}>
																<BodyText
																	textStyle={{
																		fontSize: 24,
																		color: 'white',
																	}}>
																	-
																</BodyText>
															</Box>
															<Box
																ml={'15px'}
																style={{
																	cursor: 'pointer',
																}}>
																<BodyText
																	textStyle={{ fontSize: 24, color: 'white' }}>
																	{adult}
																</BodyText>
															</Box>
															<Box
																ml={'15px'}
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	adult === 9 ? '' : setAdult(adult + 1)
																}>
																<BodyText
																	textStyle={{
																		fontSize: 24,
																		color: 'white',
																	}}>
																	+
																</BodyText>
															</Box>
														</Box>
													</Box>
												</li>
												<li>
													<Box
														style={{ padding: 10 }}
														display='flex'
														justifyContent='space-between'
														textAlign='center'
														alignItems='center'>
														<BodyText four textStyle={{ color: 'white' }}>
															Infant
														</BodyText>
														<Box display='flex' alignItems='center'>
															<Box
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	adult === 0 ? '' : setAdult(adult - 1)
																}>
																<BodyText
																	textStyle={{
																		fontSize: 24,
																		color: 'white',
																	}}>
																	-
																</BodyText>
															</Box>
															<Box
																ml={'15px'}
																style={{
																	cursor: 'pointer',
																}}>
																<BodyText
																	textStyle={{ fontSize: 24, color: 'white' }}>
																	{adult}
																</BodyText>
															</Box>
															<Box
																ml={'15px'}
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	adult === 9 ? '' : setAdult(adult + 1)
																}>
																<BodyText
																	textStyle={{
																		fontSize: 24,
																		color: 'white',
																	}}>
																	+
																</BodyText>
															</Box>
														</Box>
													</Box>
												</li>
												<li>
													<Box
														style={{ padding: 10 }}
														display='flex'
														justifyContent='space-between'>
														<Box className={classes.radio}>
															<FormControl component='fieldset'>
																<RadioGroup
																	aria-label='gender'
																	name='gender1'
																	value={value}
																	color='white'
																	onChange={handleRadio}>
																	<FormControlLabel
																		value='Economy'
																		control={<Radio />}
																		label='Economy'
																	/>
																	<FormControlLabel
																		value='Business'
																		control={<Radio />}
																		label='Business'
																	/>
																	<FormControlLabel
																		value='First Class'
																		control={<Radio />}
																		label='First Class'
																	/>
																	<FormControlLabel
																		value='Premium Economy'
																		control={<Radio />}
																		label='Premium Economy'
																	/>
																</RadioGroup>
															</FormControl>
														</Box>
													</Box>
												</li>
											</ul>
										</li>
									</ul>
								</Grid> */}
								<Grid item xs={12} md={6} lg={3}>
									<Box
										onClick={handleSubmit}
										// type="submit"
										value='Search Bus'
										style={{
											width: '100%',
											fontSize: '20px',
											borderRadius: 4,
											backgroundColor: 'rgb(0, 55, 114)',
											padding: 6,
											textAlign: 'center',
											color: 'white',
											marginTop: 20,
											cursor: 'pointer',
										}}>
										Search Bus
									</Box>
									{/* <input
                    onClick={handleSubmit}
                    // type="submit"
                    value="Search Bus"
                    style={{
                      width: '100%',
											fontSize: '20px',
											borderRadius: 4,
											backgroundColor: 'rgb(0, 55, 114)',
											padding: 8,
											textAlign: 'center',
                      color:'white',
                      marginTop:20,
                      cursor:'pointer'
                    }}
                  /> */}
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default SearchBus2;
