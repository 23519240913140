import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	withStyles,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import FlightDetailModal from '../../components/reusableComponents/FlightDetailModal';
import { setSelectedTripID } from '../../reducers/UiReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchFormDispatch } from '../../reducers/HomeReducer';
import Drawers from '../../components/Drawers';
import Login from '../../components/reusableComponents/Login';
// import OnewayForm from '../../form/OnewayForm';
import BusListForm from '../../form/BusListForm';
import { Divider } from '@mui/material';
import { Showseat } from './ShowSeat';
import {
	addBusSearchDispatch,
	fetchSeatLayoutDispatch,
} from '../../reducers/BusReducer';
import Footer from './Footer';
import SearchItem from './SearchItem';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import SkeletonOneway from '../../components/reusableComponents/SkeletonOneway';
import LoaderModal from '../../components/reusableComponents/LoaderModal';
import Linear from '../../components/reusableComponents/Linear';
import HotelModifyForm from '../../form/HotelModifyForm';
import {
	addhotelSearchDispatch,
	hotelSearchDispatch,
} from '../../reducers/HotelReducer';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-evenly',
		},
	},
	searhform: {
		// backgroundImage: `url(${Home})`,
		background: 'rgb(34, 118, 227)',
		// height: 272,
		backgroundSize: 'cover',
		paddingBottom: 22,
		backgroundRepeat: 'no-repeat',

		paddingTop: 55,

		// [theme.breakpoints.down("xs")]: {
		//   height: 590,
		// },
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
	scroll: {
		'&::-webkit-scrollbar': {
			width: '0.2em',
		},
		'&::-webkit-scrollbar-track': {
			// '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			outline: '1px solid slategrey',
			// borderTopLeftRadius: 5,
			// borderTopRightRadius: 5,
			borderRadius: 5,
		},
	},
}));

const HotelList = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = React.useState('');
	const [scrollVal, SetScrollVal] = useState(5);
	const [scrollValResult, SetScrollValResult] = useState(5);
	const [hasMoreresult, SethasMoreResult] = useState(true);
	const [hasMorefilterData, SethasMoreFilterData] = useState(true);
	const [result, setResult] = useState([]);
	const history = useHistory();
	const [rule, SetRule] = useState(false);
	const theme = useTheme();
	const location = useLocation();
	const { search, loading } = useSelector((state) => state.home);
	const { loadinghotel, hotelresult } = useSelector((state) => state.hotel);
	const { busSearch, loadingbus, layoutloading, seatLayout, loadingbus2 } =
		useSelector((state) => state.bus);
	const { selectedTripID, selectedBus, selectedDesBus } = useSelector(
		(state) => state.ui
	);
	const [stop1, setStop1] = useState(false);
	const [value, setValue] = React.useState('');
	const [val, setVal] = useState('');
	const [val2, setVal2] = useState('');

	const [stop2, setStop2] = useState(false);
	const [stop3, setStop3] = useState(false);
	const [ac, setAC] = useState(false);
	const [nonac, setNAC] = useState(false);
	const [seater, setSeater] = useState(false);
	const [sleeper, setSleeper] = useState(false);
	const [morning, setMorning] = useState(false);
	const [dophar, setDOphar] = useState(false);
	const [toggle, setToggle] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const matches2 = useMediaQuery(theme.breakpoints.down('xs'));
	console.log('Location', location);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	function valuetext(value) {
		return `${value}°C`;
	}
	function display(a) {
		var hours = Math.trunc(a / 60);
		var minutes = a % 60;
		console.log(hours + ':' + minutes);
	}
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	console.log(location.state);
	let filterData = [];
	filterData =
		hotelresult &&
		hotelresult?.result &&
		hotelresult?.result?.HotelSearchResult &&
		hotelresult?.result?.HotelSearchResult?.HotelResults?.length > 0 &&
		hotelresult?.result?.HotelSearchResult?.HotelResults?.slice().sort(
			(a, b) => {
				return a.Price.RoomPrice - b.Price.RoomPrice;
			}
		);
	console.log('fILTER', filterData);
	const handleSearch = (event) => {
		if (!event.target.value.toLowerCase()) {
			SetRule(false);
		} else {
			SetRule(true);
		}

		let value = event.target.value.toLowerCase();
		setVal(value);
		let res = [];
		res =
			hotelresult &&
			hotelresult?.result &&
			hotelresult?.result?.HotelSearchResult &&
			hotelresult?.result?.HotelSearchResult?.HotelResults?.length > 0 &&
			hotelresult?.result?.HotelSearchResult?.HotelResults?.filter((item) => {
				return item.HotelName.toLowerCase().includes(value.toLowerCase());
			});
		// setData(results);
		setResult(res);
		console.log('res', res);
	};
	const handleSearch2 = (event) => {
		if (!event.target.value.toLowerCase()) {
			SetRule(false);
		} else {
			SetRule(true);
		}

		let value = event.target.value.toLowerCase();
		setVal2(value);
		let res = [];
		res =
			hotelresult &&
			hotelresult?.result &&
			hotelresult?.result?.HotelSearchResult &&
			hotelresult?.result?.HotelSearchResult?.HotelResults?.length > 0 &&
			hotelresult?.result?.HotelSearchResult?.HotelResults?.filter((item) => {
				return item.HotelAddress.toLowerCase().includes(value.toLowerCase());
			});
		// setData(results);
		setResult(res);
		console.log('res', res);
	};
	const handleRating = (r) => {
		SetRule(true);
		setValue(r);
		let data = [];
		hotelresult &&
			hotelresult?.result &&
			hotelresult?.result?.HotelSearchResult &&
			hotelresult?.result?.HotelSearchResult?.HotelResults?.length > 0 &&
			hotelresult?.result?.HotelSearchResult?.HotelResults?.map((i) => {
				if (i.StarRating === r) {
					data.push({
						...i,
					});
				}
			});
		console.log('data', data);
		setResult(data);
	};
	console.log('result', result);
	const fetchMoreData = () => {
		if (scrollVal >= filterData.length) {
			SethasMoreFilterData(false);
		}

		setTimeout(() => {
			SetScrollVal(scrollVal + 5);
		}, 500);
	};
	const fetchMoreDataResult = () => {
		if (scrollValResult >= filterData.length) {
			SethasMoreResult(false);
		}
		setTimeout(() => {
			SetScrollValResult(scrollValResult + 5);
		}, 500);
	};

	useEffect(() => {
		if (location.state) {
			dispatch(addhotelSearchDispatch(location.state.hotelformData));
			// dispatch(hotelSearchDispatch(location.state.hotelformData));
			// dispatch(addBusSearchDispatch(location.state.formdata));
		}
	}, [dispatch]);

	//  if(busSearch &&
	//   busSearch?.result?.availableTrips)

	// console.log(location.state);
	console.log('value', result);
	// console.log(scrollVal);
	return (
		<>
			<HeaderSection />
			{loadinghotel ? (
				<Linear />
			) : (
				<Box
					className={classes.searhform}
					style={{ backgroundPosition: matches2 ? '' : '1px -443px' }}>
					<Container>
						<HotelModifyForm />
					</Container>
				</Box>
			)}
			<Box style={{ backgroundColor: 'rgb(238, 244, 253)' }}>
				<Container>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={4} style={{ marginTop: 30 }}>
								<Hidden only={['xs', 'sm']}>
									<Grid
										item
										xs={3}
										lg={3}
										style={{
											background: 'rgb(255, 255, 255)',
											borderRadius: 5,
											height: '100vh',
											marginBottom: 50,
										}}>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'>
											<span style={{ fontSize: 14, fontWeight: 600 }}>
												Hotel Filters
											</span>
											<span
												style={{ color: 'rgb(197, 197, 197)', fontSize: 14 }}>
												{/* Reset All */}
											</span>
										</Box>
										<Divider style={{ marginTop: 20 }} />
										<Box mt={'10px'}>
											<span style={{ fontSize: 16, fontWeight: 600 }}>
												Hotel Name
											</span>
											<br></br>
											<input
												type='search'
												style={{
													border: '1px solid grey',
													padding: 18,
													width: '100%',
													marginTop: 10,
													fontSize: 16,
												}}
												placeholder='Search Hotel Name'
												value={val}
												onChange={handleSearch}
											/>
										</Box>
										<Divider style={{ marginTop: 20 }} />
										<Box mt={'10px'}>
											<span style={{ fontSize: 16, fontWeight: 600 }}>
												Hotel Location
											</span>
											<br></br>
											<input
												type='search'
												style={{
													border: '1px solid grey',
													padding: 20,
													width: '100%',
													marginTop: 10,
													fontSize: 16,
												}}
												placeholder='Search Hotel Location'
												value={val2}
												onChange={handleSearch2}
											/>
										</Box>
										{/* {Array.from(Array(3)).map((i)=>{
                    return  <Box  mt={'10px'}>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                        Hotel Location
                      </span>
                      <br></br>
                      <input type='search' style={{border:'1px solid grey',padding:20,width:'100%',marginTop:10,fontSize:16}} placeholder='Search Hotel Location' />
											
										</Box>
                   })} */}
										<Divider style={{ marginTop: 20 }} />
										<Box
											display='flex'
											justifyContent='space-between'
											mt={'20px'}
											alignItems='center'>
											<span style={{ fontSize: 14, fontWeight: 600 }}>
												Star Rating
											</span>
											<span
												style={{ color: 'rgb(197, 197, 197)', fontSize: 16 }}>
												{/* Reset */}
											</span>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												onClick={() => handleRating(1)}
												style={{
													background:
														value === 1 ? '#0071c2' : 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													padding: '0.7rem 0px',
													fontSize: 14,
													cursor: 'pointer',
												}}>
												<span
													style={{ color: value === 1 ? 'white' : 'black' }}>
													0/1{' '}
												</span>
												<i
													class='fa-solid fa-star'
													style={{ color: value === 1 ? 'white' : '' }}></i>
											</Box>
											<Box
												onClick={() => handleRating(2)}
												style={{
													background:
														value === 2 ? '#0071c2' : 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
													fontSize: 14,
													cursor: 'pointer',
												}}>
												<span style={{ color: value === 2 ? 'white' : '' }}>
													2{' '}
												</span>
												<i
													class='fa-solid fa-star'
													style={{ color: value === 2 ? 'white' : '' }}></i>
											</Box>
											<Box
												onClick={() => handleRating(3)}
												style={{
													background:
														value === 3 ? '#0071c2' : 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
													fontSize: 14,
													cursor: 'pointer',
												}}>
												<span style={{ color: value === 3 ? 'white' : '' }}>
													3
												</span>
												<i
													class='fa-solid fa-star'
													style={{ color: value === 3 ? 'white' : '' }}></i>
											</Box>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												onClick={() => handleRating(4)}
												style={{
													background:
														value === 4 ? '#0071c2' : 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													padding: '0.7rem 0px',
													fontSize: 14,
													cursor: 'pointer',
												}}>
												<span style={{ color: value === 4 ? 'white' : '' }}>
													4{' '}
												</span>
												<i
													class='fa-solid fa-star'
													style={{ color: value === 4 ? 'white' : '' }}></i>
											</Box>
											<Box
												onClick={() => handleRating(5)}
												style={{
													background:
														value === 5 ? '#0071c2' : 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
													fontSize: 14,
													cursor: 'pointer',
												}}>
												<span style={{ color: value === 5 ? 'white' : '' }}>
													5{' '}
												</span>
												<i
													class='fa-solid fa-star'
													style={{ color: value === 5 ? 'white' : '' }}></i>
											</Box>
										</Box>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={9} lg={9}>
									{loadinghotel ? (
										[0, 1, 2, 3, 4].map((i) => {
											return <SkeletonOneway />;
										})
									) : loadinghotel ? (
										[0, 1, 2, 3, 4].map((i) => {
											return <SkeletonOneway />;
										})
									) : rule || (result && result.length > 0) ? (
										result && result.length > 0 ? (
											<InfiniteScroll
												dataLength={scrollValResult}
												next={fetchMoreDataResult}
												hasMore={hasMoreresult}
												loader={<h4>Loading...</h4>}
												endMessage={
													<p style={{ textAlign: 'center' }}>
														<b>No More Hotel</b>
													</p>
												}>
												{result &&
													result.map((item, index) => {
														return <SearchItem index={index} item={item} />;
													})}
												{/* <SearchItem index={index} item={item} /> */}
											</InfiniteScroll>
										) : (
											<div
												style={{
													padding: '30px 15px',
													background: 'white',
													borderBottom: '1px solid #e6e6e6',
													textAlign: 'center',
												}}>
												<img src='https://cdn.dribbble.com/users/6498639/screenshots/15138706/media/0262f2a4841a14755bd96261e11b6334.gif'></img>
												<p style={{ fontSize: '24px', textAlign: 'center' }}>
													Sorry No Hotels Found !!
												</p>
											</div>
										)
									) : filterData && filterData.length > 0 ? (
										<InfiniteScroll
											dataLength={scrollVal}
											next={fetchMoreData}
											hasMore={hasMorefilterData}
											loader={
												<h4 style={{ textAlign: 'center' }}>Loading...</h4>
											}
											endMessage={
												<p style={{ textAlign: 'center' }}>
													<b>No More Hotel</b>
												</p>
											}>
											{filterData.map((item, index) => {
												return index < scrollVal ? (
													matches ? (
														<>
															<SearchItem index={index} item={item} />
														</>
													) : (
														<>
															<SearchItem index={index} item={item} />
														</>
													)
												) : (
													''
												);
											})}
										</InfiniteScroll>
									) : !loadinghotel ? (
										<div
											style={{
												padding: '30px 15px',
												background: 'white',
												borderBottom: '1px solid #e6e6e6',
												textAlign: 'center',
											}}>
											<img
												src='https://cdn.dribbble.com/users/6498639/screenshots/15138706/media/0262f2a4841a14755bd96261e11b6334.gif'
												height='300px'></img>
											<p style={{ fontSize: '24px', textAlign: 'center' }}>
												Sorry No Hotels Found !!
											</p>
										</div>
									) : (
										[0, 1, 2, 3, 4].map((i) => {
											return <SkeletonOneway />;
										})
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<FlightDetailModal />
			<Footer />
			<Drawers />
			{layoutloading || loadingbus2 ? <LoaderModal /> : ''}
			<Login />
		</>
	);
};

export default HotelList;
