import React, { useState, useEffect } from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
	Popper,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import flt from '../assets/images/flt.png';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeNew from '../components/reusableComponents/DateRangeNew';
import BodyText from '../components/reusableComponents/BodyText';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { fetchCityDispatch } from '../reducers/HomeReducer';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import DesktopDateNew from '../components/reusableComponents/DesktopDateNew';
import {
	setSelectedDepart,
	setSelectedOrigin,
	setSelectedTrip,
} from '../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'grey',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '.8em',
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
			// fontSize: '14px',
			//   fontWeight: 800,
		},
	},
	radio2: {
		'& .MuiRadio-root': {
			color: 'black',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '.8em',
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
			// fontSize: '14px',
			// fontWeight: 800,
		},
	},
	popper: {
		'& .MuiAutocomplete-listbox': {
			'& :hover': {
				color: 'rgb(239, 51, 57)',
				// '& img': {
				// 	background: 'rgb(239, 51, 57)',
				// },
			},
		},
	},
}));
const SearchEngineArya = () => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState(false);
	const classes = useStyles();
	const { city } = useSelector((state) => state.home);
	const { agentlogin } = useSelector((state) => state.user);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [toggle, setToggle] = useState(false);
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [trip, setTrip] = useState('oneway');
	const [going, setGoing] = useState('CCU');
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const { origin, depart, OriginO, selectedOrigin, selectedDepart, tripInfo } =
		useSelector((state) => state.ui);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const handleSubmit = (data) => {
		// console.log(data);
		let org = data.Origin;
		let des = data.Destination;
		let formData = {
			AdultCount: adult,
			ChildCount: child,
			InfantCount: infant,
			DirectFlight: false,
			OneStopFlight: false,
			JourneyType: 1,
			PreferredAirlines: null,
			Segments: [
				{
					Origin: selectedOrigin.airport_code,
					Destination: selectedDepart.airport_code,
					FlightCabinClass: 1,
					PreferredDepartureTime: origin,
				},
			],
			Sources: 'WEB',
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
		};
		let formData2 = {
			AdultCount: adult,
			ChildCount: child,
			InfantCount: infant,
			DirectFlight: false,
			OneStopFlight: false,
			JourneyType: 2,
			PreferredAirlines: null,
			Segments: [
				{
					Origin: selectedOrigin.airport_code,
					Destination: selectedDepart.airport_code,
					FlightCabinClass: 1,
					PreferredDepartureTime: origin,
				},
				{
					Origin: selectedDepart.airport_code,
					Destination: selectedOrigin.airport_code,
					FlightCabinClass: 1,
					PreferredDepartureTime: depart,
				},
			],
			Sources: 'WEB',
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
		};
		if (tripInfo === 'oneway') {
			history.push({
				pathname: `/oneway/${selectedOrigin.airport_code}/${selectedDepart.airport_code}/${origin}`,
				state: {
					body: formData,
					OriginO: origin,
				},
			});
		} else {
			history.push({
				pathname:
					selectedOrigin.country_code === 'IN' &&
					selectedDepart.country_code === 'IN'
						? `/roundway/${selectedOrigin.airport_code}/${selectedDepart.airport_code}/${origin}/${selectedDepart.airport_code}/${selectedOrigin.airport_code}/${depart}`
						: '/international',
				state: { returnBody: formData2 },
			});
		}
	};
	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchCityDispatch(cityData, setResult));
	};
	const handleChangeTrip = (e) => {
		dispatch(setSelectedTrip(e.target.value));
	};
	// useEffect(() => {
	// 	let cityData = {
	// 		keywords: 'bo',
	// 		limits: 8,
	// 	};
	// 	dispatch(fetchCityDispatch(cityData, setResult));
	// }, []);
	const PopperMy = function (props) {
		return (
			<Popper
				{...props}
				style={styles.popper}
				className={classes.popper}
				placement='bottom-start'
			/>
		);
	};

	const styles = (theme) => ({
		popper: {
			width: 'fit-content',
		},
	});
	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form
							style={{
								background: 'white',
								// marginTop: '25px',
								paddingLeft: 20,
								paddingRight: 20,
								boxShadow: '-1px 3px 5px 0 rgb(0 0 0 / 16%)',
							}}>
							<Box className={classes.radio}>
								<FormControl onChange={handleChangeTrip}>
									<RadioGroup
										row
										aria-labelledby='demo-row-radio-buttons-group-label'
										name='row-radio-buttons-group'>
										<FormControlLabel
											value='oneway'
											control={
												<Radio checked={tripInfo == 'oneway' ? true : false} />
											}
											label='ONE WAY'
										/>
										<FormControlLabel
											value='roundtrip'
											control={
												<Radio
													checked={tripInfo == 'roundtrip' ? true : false}
												/>
											}
											label='ROUND TRIP'
										/>
									</RadioGroup>
								</FormControl>
							</Box>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} md={12} lg={tripInfo == 'roundtrip' ? 4 : 5}>
									<Grid container alignItems='center' spacing={1}>
										{/* Date Picker Starting */}
										<Grid
											item
											xs={12}
											lg={6}
											style={{ display: 'flex', alignItems: 'center' }}>
											<div className={classes.root} style={{ width: '100%' }}>
												<span
													style={{
														color: 'black',
														fontSize: matches ? 12 : '.8em',
													}}>
													Leaving From
												</span>
												<Autocomplete
													id='country-select-demo'
													sx={{
														// background: "#eee",
														border: '1px solid lightgrey',
														borderRadius: '4px',
														padding: '7px',
														transition: 'none',
													}}
													clearOnBlur={true}
													value={selectedOrigin}
													PopperComponent={PopperMy}
													options={
														city && city.result.length > 0 ? city.result : []
													}
													filterOptions={(options, state) => options}
													autoHighlight
													getOptionLabel={(option) =>
														`${option.city_name}, ${option.country_name} `
													}
													renderOption={(props, option) => (
														<Box
															// mr={'5px'}
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																alignItems: 'center',
															}}
															component='li'
															sx={{ '& > img': { mr: 2 } }}
															{...props}>
															<Box display='flex'>
																<img
																	loading='lazy'
																	width='20'
																	src={
																		'https://www.freeiconspng.com/thumbs/airplane-icon-png/transport-airplane-takeoff-icon--android-iconset--icons8-2.png'
																	}
																	alt=''
																/>
																<p style={{ fontSize: 15, marginLeft: 5 }}>
																	{option.city_name} ({option.airport_code}){' '}
																	{option.airport_name}
																</p>
															</Box>
															<img
																style={{ marginLeft: 10 }}
																loading='lazy'
																width='30'
																src={`https://flagcdn.com/${option.country_code.toLowerCase()}.svg`}
																alt=''
															/>
														</Box>
													)}
													onChange={(event, value) => {
														setFieldValue('Origin', value.airport_code);
														dispatch(setSelectedOrigin(value));
														setFrom(value);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={handleSearchOrigin}
															name='origin'
														/>
													)}
												/>
											</div>
											{/* <Hidden only={['xs', 'sm']}> */}
											<img
												onClick={() => {
													dispatch(setSelectedDepart(selectedOrigin));
													dispatch(setSelectedOrigin(selectedDepart));
												}}
												src={
													'https://icons.veryicon.com/png/o/miscellaneous/mirror-icon/swap-8.png'
												}
												style={{
													height: 18,
													position: 'inherit',
													widht: '100%',
													marginTop: 20,
													marginLeft: 2,
													cursor: 'pointer',
												}}
											/>
											{/* </Hidden> */}
										</Grid>
										<Grid item xs={12} lg={6}>
											<div className={classes.root}>
												<span
													style={{
														color: 'black',
														fontSize: matches ? 12 : '.8em',
													}}>
													Going To
												</span>
												<Autocomplete
													id='country-select-demo'
													sx={{
														border: '1px solid lightgrey',
														borderRadius: '4px',
														padding: '7px',
														transition: 'none',
													}}
													clearOnBlur={true}
													options={
														city && city.result.length > 0 ? city.result : []
													}
													value={selectedDepart}
													PopperComponent={PopperMy}
													filterOptions={(options, state) => options}
													autoHighlight
													getOptionLabel={(option) =>
														`${option.city_name}, ${option.country_name} `
													}
													renderOption={(props, option) => (
														<Box
															// mr={'5px'}
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																alignItems: 'center',
															}}
															component='li'
															sx={{ '& > img': { mr: 2 } }}
															{...props}>
															<Box display='flex'>
																<img
																	loading='lazy'
																	width='20'
																	src={
																		'https://www.freeiconspng.com/thumbs/airplane-icon-png/transport-airplane-takeoff-icon--android-iconset--icons8-2.png'
																	}
																	alt=''
																/>
																<p style={{ fontSize: 15, marginLeft: 5 }}>
																	{option.city_name} ({option.airport_code}){' '}
																	{option.airport_name}
																</p>
															</Box>
															<img
																style={{ marginLeft: 10 }}
																loading='lazy'
																width='30'
																src={`https://flagcdn.com/${option.country_code.toLowerCase()}.svg`}
																alt=''
															/>
														</Box>
													)}
													onChange={(event, value) => {
														setFieldValue('Destination', value.airport_code);
														// console.log(value.airport_code);
														dispatch(setSelectedDepart(value));
														setTo(value);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={handleSearchOrigin}
														/>
													)}
												/>
											</div>
										</Grid>
										{/* Date Picker Ending */}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={
										tripInfo == 'oneway' ? 2 : tripInfo == 'roundtrip' ? 4 : 2
									}>
									<Box display='flex' justifyContent='space-between'>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
												marginBottom: 4,
											}}>
											Depart Date
										</span>
										{tripInfo == 'oneway' ? (
											''
										) : tripInfo == 'roundtrip' ? (
											<span
												style={{
													color: 'black',
													marginBottom: 4,
													fontSize: matches ? 12 : '.8em',
												}}>
												Return Date
											</span>
										) : (
											''
										)}
									</Box>
									{tripInfo == 'oneway' ? (
										<DesktopDateNew />
									) : tripInfo == 'roundtrip' ? (
										<DateRangeNew />
									) : (
										<DesktopDateNew />
									)}
								</Grid>
								{/* Travellers Strating */}
								<Grid item xs={12} md={6} lg={tripInfo == 'oneway' ? 3 : 2}>
									<ul
										class='menu'
										style={{
											marginTop: 20,
											borderRadius: 2,
											paddingLeft: 0,
											cursor: 'pointer',
											// width: trip == "roundtrip" ? "110%" : "95%",
										}}>
										<li>
											<div
												onClick={() =>
													toggle ? setToggle(false) : setToggle(true)
												}
												style={{
													padding: 8,
													fontSize: matches ? 12 : '.9em',
													borderRadius: 4,
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													fontWeight: 600,
													border: '1px solid lightgrey',
													textAlign: 'center',
												}}>
												Travellers{' '}
												{Number(adult) + Number(child) + Number(infant)}
											</div>

											{toggle ? (
												<>
													{' '}
													<ul style={{ padding: 5, height: 250 }}>
														<li>
															<Box
																style={{ padding: 5 }}
																display='flex'
																justifyContent='space-between'
																textAlign='center'
																alignItems='center'>
																<BodyText
																	four
																	textStyle={{
																		color: 'black',
																		fontSize: 16,
																		fontWeight: 500,
																	}}>
																	Adult
																</BodyText>
																<Box display='flex' alignItems='center'>
																	<Box
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}
																		onClick={() =>
																			adult === 0 ? '' : setAdult(adult - 1)
																		}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			-
																		</BodyText>
																	</Box>
																	<Box
																		ml={'15px'}
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			{adult}
																		</BodyText>
																	</Box>
																	<Box
																		ml={'15px'}
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}
																		onClick={() =>
																			adult + child + infant === 9
																				? adult
																				: setAdult(adult + 1)
																		}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			+
																		</BodyText>
																	</Box>
																</Box>
															</Box>
														</li>
														<li>
															<Box
																style={{ padding: 5 }}
																display='flex'
																justifyContent='space-between'
																textAlign='center'
																alignItems='center'>
																<BodyText
																	four
																	textStyle={{
																		color: 'black',
																		fontSize: 16,
																		fontWeight: 500,
																	}}>
																	Child
																</BodyText>
																<Box display='flex' alignItems='center'>
																	<Box
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}
																		onClick={() =>
																			child === 0 ? '' : setChild(child - 1)
																		}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			-
																		</BodyText>
																	</Box>
																	<Box
																		ml={'15px'}
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			{child}
																		</BodyText>
																	</Box>
																	<Box
																		ml={'15px'}
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}
																		onClick={() =>
																			adult + child + infant === 9
																				? child
																				: setChild(child + 1)
																		}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			+
																		</BodyText>
																	</Box>
																</Box>
															</Box>
														</li>
														<li>
															<Box
																style={{ padding: 5 }}
																display='flex'
																justifyContent='space-between'
																textAlign='center'
																alignItems='center'>
																<BodyText
																	four
																	textStyle={{
																		color: 'black',
																		fontSize: 16,
																		fontWeight: 500,
																	}}>
																	Infant
																</BodyText>
																<Box display='flex' alignItems='center'>
																	<Box
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}
																		onClick={() =>
																			infant === 0 ? '' : setInfant(infant - 1)
																		}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			-
																		</BodyText>
																	</Box>
																	<Box
																		ml={'15px'}
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			{infant}
																		</BodyText>
																	</Box>
																	<Box
																		ml={'15px'}
																		style={{
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			padding: '0px 10px',
																			borderRadius: '5px',
																		}}
																		onClick={() =>
																			adult + child + infant === 9
																				? infant
																				: setInfant(infant + 1)
																		}>
																		<BodyText
																			textStyle={{
																				fontSize: 16,
																				color: 'white',
																			}}>
																			+
																		</BodyText>
																	</Box>
																</Box>
															</Box>
														</li>
														<li>
															<Box
																style={{ padding: 10 }}
																display='flex'
																alignItems='center'
																justifyContent='space-between'>
																<Box className={classes.radio2}>
																	<FormControl component='fieldset'>
																		<RadioGroup
																			aria-label='gender'
																			name='gender1'
																			value={value}
																			color='black'
																			onChange={handleRadio}>
																			<FormControlLabel
																				value='Economy'
																				control={<Radio />}
																				label='Economy'
																			/>
																			<FormControlLabel
																				value='Business'
																				control={<Radio />}
																				label='Business'
																			/>
																			{/* <FormControlLabel
																				value='First Class'
																				control={<Radio />}
																				label='First Class'
																			/> */}
																			<FormControlLabel
																				value='Premium Economy'
																				control={<Radio />}
																				label='Premium Economy'
																			/>
																		</RadioGroup>
																	</FormControl>
																</Box>
																<Box
																	style={{ background: 'darkcyan', padding: 5 }}
																	onClick={() => setToggle(false)}>
																	<span
																		style={{
																			color: 'white',
																			cursor: 'pointer',
																			fontSize: 12,
																		}}>
																		Done
																	</span>
																</Box>
															</Box>
														</li>
														{/* <li></li> */}
													</ul>
												</>
											) : (
												''
											)}
										</li>
									</ul>
								</Grid>
								{/* Travellers Ending */}

								{/* Book Button Starting */}
								<Grid item xs={12} md={6} lg={2} style={{ marginTop: 5 }}>
									<input
										// type='submit'
										onClick={handleSubmit}
										value='Search'
										className='btn'
										style={{
											width: '100%',
											fontSize: '16px',
											borderRadius: 4,
											backgroundColor: 'rgb(0, 55, 114)',
											padding: 8,
											textAlign: 'center',
										}}
									/>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default SearchEngineArya;
